import { Action, useRegisterActions } from 'kbar';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useEventCallback } from '@mui/material';
import { Apps as AppsIcon, Search as SearchIcon } from '@mui/icons-material';

import { GenericFailure } from '@playq/services-shared';
import { App, AppFeature } from '@playq/octopus2-apps';
import { AppID } from '@playq/octopus-common';

import { IWithAppsListProps } from '/common/Kbar/types';
import { snackbarService } from '/common/snackbarService';
import { AppIcon } from '/component/Apps/AppIcon';
import { KBAR_SECTION } from '/common/Kbar/constants';
import { appToolkit, kbarToolkit, useAppDispatch } from '/store';
import { selectApp as selectAppAction } from '/store/toolkits/app/actions';
import { throwGenericFailure } from '/helpers';
import { services2 } from '/api';

export const ShortcutsAction = ({ config }: IWithAppsListProps) => {
  const { apps = [], refetch } = config;
  const shouldUpdateApps = useSelector(kbarToolkit.selectors.shouldUpdateApps);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shouldUpdateApps) {
      refetch();
      dispatch(kbarToolkit.actions.setShouldUpdateApps(false));
    }
  }, [dispatch, refetch, shouldUpdateApps]);

  const app = useSelector(appToolkit.selectors.app) as App;
  const [prevApp, setPrevApp] = useState<App>(app);

  const rollBackPrevApp = useEventCallback(() => dispatch(selectAppAction({ app: prevApp })));

  const handlerFailure = useCallback((err: Error | GenericFailure) => {
    console.error(err.message);
    snackbarService.error(`AppFetch: ${err.message}`);
  }, []);

  const parentSelectAppAction: Action = useMemo(
    () => ({
      id: 'selectAppAction',
      name: 'Apps',
      keywords: 'select app',
      section: KBAR_SECTION.SHORTCUTS,
      shortcut: ['s', 'a'],
      subtitle: 'Select from you favorite app',
      icon: <AppsIcon />,
    }),
    []
  );

  const selectApp = useMemo((): Action[] => {
    const basicAppSelect = {
      ...parentSelectAppAction,
      name: 'Select app',
      id: 'selectAppModalAction',
      parent: 'selectAppAction',
      subtitle: 'Open modal for app select',
      section: KBAR_SECTION.APPS_ACTION,
      priority: 0,
      perform: () => {
        setTimeout(() => dispatch(kbarToolkit.actions.setOpenSelectAppModal(true)), 100);
      },
    };

    if (apps.length === 0) {
      return [basicAppSelect];
    }

    const appsList: Action[] = apps.map((favApp) => ({
      id: `${favApp.id.serialize()}Action`,
      name: _.capitalize(favApp.name),
      keywords: favApp.name,
      section: KBAR_SECTION.FAV_APP,
      parent: 'selectAppAction',
      icon: <AppIcon app={new App(favApp.serialize())} lessShadow={true} />,
      perform: () => {
        const selected = new AppID(favApp.id);
        services2.appsService
          .retrieve(selected, [AppFeature.FingerprintSalts])
          .then((data) => {
            data.bifold(
              (retrievedApp) => {
                setPrevApp(app);
                dispatch(selectAppAction({ app: new App(favApp.serialize()) }));

                if (prevApp !== undefined) {
                  snackbarService.custom({
                    msg: `App was switched to ${_.capitalize(retrievedApp.name)}`,
                    customAction: rollBackPrevApp,
                    type: 'kBarSnackBar',
                    buttonText: 'Switch back',
                  });

                  return rollBackPrevApp;
                }

                snackbarService.custom({
                  msg: `Application selected: ${_.capitalize(retrievedApp.name)}`,
                  type: 'kBarSnackBar',
                });
              },
              (err) => {
                throwGenericFailure(err);
              }
            );
          })
          .catch((err: Error) => {
            handlerFailure(err);
          });
      },
    }));
    appsList.push(basicAppSelect);

    return appsList;
  }, [parentSelectAppAction, apps, dispatch, app, prevApp, rollBackPrevApp, handlerFailure]);

  const actions: Action[] = [
    {
      id: 'focusToSearchAction',
      name: 'Search',
      keywords: 'search',
      section: KBAR_SECTION.SHORTCUTS,
      shortcut: ['s'],
      icon: <SearchIcon />,
      perform: () => {
        setTimeout(() => dispatch(kbarToolkit.actions.setSearchInputFocused(true)), 100);
      },
      subtitle: 'Focusing search field is available',
    },
    parentSelectAppAction,
    ...selectApp,
  ];

  useRegisterActions(actions, [apps]);

  return null;
};
