import { IWithAppsListProps } from '/common/Kbar/types';
import { NavigationActions } from '/common/Kbar/actions/NavigationAction';
import { ModuleAction } from '/common/Kbar/actions/ModuleAction';

import { ShortcutsAction } from './ShortcutsAction';
import { ThemeActions } from './ThemeAction';

export const Actions = ({ config }: IWithAppsListProps) => {
  const isDev = __CI_BRANCH__ === 'develop' || !__CI_BRANCH__;

  return (
    <>
      <ShortcutsAction config={config} />
      <ThemeActions />
      <ModuleAction />
      {isDev && <NavigationActions />}
    </>
  );
};
