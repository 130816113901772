import { KBarPortal } from 'kbar';

import { OffsetLimit } from '@playq/services-shared';
import { AppsFilterField } from '@playq/octopus2-apps';
import { BooleanFilter } from '@playq/octopus-common';

import { Actions } from '/common/Kbar/actions/Actions';
import { StyledKBarAnimator, StyledKBarPositioner, StyledKBarSearch } from '/common/Kbar/styles';
import { CommandBarResult } from '/common/Kbar/CommandBarResult';
import { iteratorMaxLimit } from '/constants';
import { useAppsQuery } from '/api';

export const CommandBar = () => {
  const defaultQuery = {
    iterator: new OffsetLimit({ limit: iteratorMaxLimit, offset: 0 }),
    sortBy: [],
    filterBy: { [AppsFilterField.Favourites]: new BooleanFilter({ value: true }) },
  };
  const { apps, refetch } = useAppsQuery(defaultQuery.iterator, defaultQuery.sortBy, defaultQuery.filterBy);

  return (
    <KBarPortal data-testid={'kbar-portal'}>
      <StyledKBarPositioner data-testid={'kbar'}>
        <StyledKBarAnimator>
          <StyledKBarSearch data-testid={'kbar-search'} />
          <CommandBarResult data-testid={'kbar-result'} />
        </StyledKBarAnimator>
        <Actions config={{ apps, refetch }} />
      </StyledKBarPositioner>
    </KBarPortal>
  );
};
